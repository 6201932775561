import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nueva-venta',
  templateUrl: './nueva-venta.component.html',
  styleUrls: ['./nueva-venta.component.css']
})
export class NuevaVentaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
