import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  send = {
    usuario: null,
    password: null
  };
  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
  }
login(){
  this.loginService.iniciaSesion(this.send)
}
}
