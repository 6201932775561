import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HelpComponent } from './pages/help/help.component';
import { UsuarioGuard } from './usuario.guard';

const routes: Routes = [
  {path:'login', component: LoginComponent},
  {path:'help', component: HelpComponent, canActivate: [UsuarioGuard]},
  {path:'**',redirectTo:'help'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
