    <div class="login-page" style="min-height: 512.391px;">
    <div class="login-box">
      <div class="login-logo">
        <img src="../../assets/logo.png">
      </div>
      <!-- /.login-logo -->
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg">Usa tus credenciales para Ingresar</p>
    
          <form  (ngSubmit) ="login()">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Usuario" [(ngModel)]="send.usuario" name="usuario">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-user"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Contraseña" [(ngModel)]="send.password" name="password">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              
              <!-- /.col -->
              <div class="col-12">
                <button type="submit" class="btn btn-primary btn-block">Ingresar</button>
              </div>
              <!-- /.col -->
            </div>
          </form>
    
         
          
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
    <!-- /.login-box -->
</div>