import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  userLog = false;
  constructor(private httpClient: HttpClient, private router: Router, private cookiesServices : CookieService) { }
  

  iniciaSesion(credencials: object){
    return this.httpClient.post<any>('https://imagitec.mx/api/v1/usuario/login', credencials).subscribe( data => {
      if(data.status === 200){
        this.router.navigate(['help']);
        this.cookiesServices.set('usuario', data.data.usuario);
        this.cookiesServices.set('token', data.data.token);
      }else{
        /* this.mensaje = data.mensaje; */
        console.log('No Login');
      }
      } );

  }

  checkSesion(){
    return this.httpClient.get<any>('https://imagitec.mx/api/v1/usuario/isLoggedIn').subscribe(data => {
      console.log('Entro en verificacion');
      if (data.status === 200){
        this.userLog = true;
        this.router.navigate(['help']);
      }else{
        this.userLog = false;
      }
    });

  }
  logOut(){
    return this.httpClient.get<any>('https://imagitec.mx/api/v1/usuario/cerrarSesion').subscribe(data => {
        if (data.status === 200){
        this.userLog = false;
        this.router.navigate(['login']);
        this.cookiesServices.delete('usuario');
        this.cookiesServices.delete('token');
        }
    });

  }

  isAutenticado(){
    console.log('Entro en isAuth');
    return  this.userLog;
}
}
